.purchases {
  max-width: 736px;
  margin: 0 auto;
  padding: 24px;
  background: $n7;
  border-radius: 24px;
  overflow: hidden;
  @include m {
    margin: 0 -24px;
    border-radius: 0; }

  &__list {
    margin-bottom: -49px; }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $n6;
    @include m {
      display: block; } }

  &__preview {
    flex: 0 0 166px;
    margin-right: 24px;
    background: $n9;
    border-radius: 16px;
    @include m {
      display: none; }
    img {
      border-radius: 16px; } }

  &__inner {
    flex: 0 0 calc(100% - 190px); }

  &__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    @include h6-book; }

  &__title {
    color: white; }

  &__price {
    display: flex;
    margin-left: 24px; }

  &__full {
    margin-right: 8px;
    color: $n3;
    text-decoration: line-through; }

  &__description {
    margin-bottom: 22px;
    @include base2-medium;
    color: $n3; }

  &__actions {
    display: flex;
    .btn {
      margin-right: 16px; } } }
