.login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 112px 0 48px;
  background-color: $n10;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: auto;
  @include rh(840) {
    padding: 40px 0; }
  @include m {
    padding: 0; }

  &__container {
    width: 100%;
    max-width: 500px;
    margin: auto;
    @include m {
      max-width: 100%;
      margin: 0;
      padding: 0; } }

  &__inner {
    position: relative;
    padding: 48px 40px;
    background: $n7;
    border-radius: 24px;
    @include m {
      min-height: 100vh;
      padding: 40px 24px;
      border-radius: 0; } }

  &__back {
    position: absolute;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: $n6;
    border-radius: 20px;
    transition: background .2s;
    &:hover {
      background: $n9;
      svg {
        fill: $blue; } }
    svg {
      width: 24px;
      height: 24px;
      fill: $n3;
      transition: fill .2s; } }

  &__logo {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 16px; }

  &__title {
    margin-bottom: 32px;
    @include h4;
    text-align: center; }

  &__text {
    margin: -24px 0 32px;
    color: $n3;
    text-align: center; }

  &__form {
    .field {
      margin-bottom: 24px; }

    .field__input,
    .btn,
    .loader {
      height: 56px;
      @include rh(840) {
        height: 48px; } }

    .field__action {
      top: 16px;
      @include rh(840) {
        top: 12px; }
      svg {
        width: 24px;
        height: 24px; } }

    .btn--google {
      margin-top: 24px;
      background: $n9;
      border-color: $n9;
      svg {
        margin: 0 10px 0 0; } } }

  &__qr {
    margin-bottom: 32px;
    padding: 8px;
    background: white;
    border-radius: 8px; }

  &__hint {
    margin-top: 32px;
    @include caption1-book;
    color: rgba($n1,.75);
    text-align: center;
    button,
    a {
      @include button-s;
      color: $n1;
      &:hover {
        text-decoration: underline; } } }

  &__copyright {
    margin-top: 48px;
    @include caption2-medium;
    opacity: .5;
    text-align: center;
    @include rh(840) {
      display: none; } } }
